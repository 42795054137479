<template>
  <div class="chart widget-box">
    <div class="chart-area-wrapper">
      <div class="chart-wrapper">
        <canvas :ref="elementRef"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js';
import { chartProps } from '../../core/utils/LineChartHelper';

export default {
  name: 'chart',
  props: {
    ...chartProps,
  },
  data() {
    return {
      gradient: null,
    };
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    convertHex(colorCode, opacity) {
      if (colorCode.includes('#')) {
        const hex = colorCode.replace('#', '');
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);
        return `rgba(${r},${g},${b},${opacity})`;
      }
      const strArr = colorCode.split(',');
      if (strArr && strArr.length > 3) {
        strArr[strArr.length - 1] = strArr[strArr.length - 1].replace('1', opacity);
        return strArr.join(',');
      }
      return colorCode;
    },
    prepareGradient(bgColor) {
      const gradient = this.$refs[this.elementRef].getContext('2d').createLinearGradient(0, 0, 0, 200);
      if (bgColor) {
        gradient.addColorStop(0, this.convertHex('#0085FF', '1'));
        gradient.addColorStop(0.5, this.convertHex('#0085FF', '1'));
        gradient.addColorStop(1, this.convertHex('#0085FF', '1'));
      }
      return gradient;
    },
    borderGradient(bdrColor) {
      const gradient = this.$refs[this.elementRef].getContext('2d').createLinearGradient(0, 0, 0, 500);
      if (bdrColor) {
        gradient.addColorStop(0, this.convertHex('#0085FF', 1));
        gradient.addColorStop(0.5, this.convertHex('#0085FF', 1));
        gradient.addColorStop(1, this.convertHex('#0085FF', 1));
      }
      return gradient;
    },
    renderChart() {
      let bgColor = this.backgroundColor;
      let bdrColor = this.borderColor;
      if (bdrColor && Array.isArray(bdrColor) && bdrColor.length === 1) {
        bdrColor = this.borderGradient(bdrColor[0]);
      }
      if (bgColor && Array.isArray(bgColor) && bgColor.length === 1) {
        bgColor = this.prepareGradient(bgColor[0]);
      }
      // eslint-disable-next-line no-new
      new Chart(this.$refs[this.elementRef], {
        type: this.chartType,
        data: {
          labels: this.labels,
          datasets: [{
            data: this.dataArray,
            type: this.chartType,
            label: this.datalabel,
            backgroundColor: bgColor,
            hoverBackgroundColor: this.convertHex(this.backgroundColor[0], '1'),
            borderColor: bdrColor,
            pointBackgroundColor: this.pointBgColor,
            pointHoverBackgroundColor: this.pointHoverBgColor,
            pointHoverBordergroundColor: this.pointHoverBorderColor,
            borderWidth: this.borderWidth,
            pointBorderColor: this.pointBorderColor,
            fill: false,
          }],
        },
        options: this.options,
      });
    },
  },
};
</script>
<style lang="scss">
.widget-box {
  position: relative;
  & > canvas {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
  }
}
.chart-area-wrapper {
  width: 100%;
  overflow-x: auto;
}
</style>
