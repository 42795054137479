/* eslint-disable max-len */
export const chartProps = {
  chartType: {
    type: String,
    default: 'bar',
  },
  backgroundColor: {
    type: Array,
    default: ['#9182DE'],
  },
  borderColor: {
    type: Array,
    default: ['#9182DE'],
  },
  datalabel: {
    type: String,
    default: 'Demo Chart',
  },
  pointBgColor: {
    type: String,
    default: '#fff',
  },
  pointHoverBgColor: {
    type: String,
    default: '#fff',
  },
  pointHoverBorderColor: {
    type: String,
    default: '#fff',
  },
  pointBorderColor: {
    type: String,
    default: ['#fff'],
  },
  type: {
    type: String,
    default: 'line',
  },
  dataSets: {
    type: Array,
    default: null,
  },
  dataArray: {
    type: Array,
    default: null,
  },
  options: {
    type: Object,
    default() {
      return {
        responsive: true,
        curvature: 10,
        maintainAspectRatio: false,
        lineTension: 0,
        elements: {
          point: {
            radius: 1,
          },
          line: {
            tension: 0,
          },
        },
        datasets: { bar: { categoryPercentage: 0.5, barPercentage: 0.9 } },
        scales: {
          xAxes: [{
            barThickness: 40,
            display: true,
            gridLines: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              autoSkip: false,
              fontSize: 14,
            },
          }],
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: true,
              borderDash: [8, 4],
              color: '#E8E8E8',
              drawBorder: false,
            },
          }],
        },
        tooltips: {
          mode: 'index',
          position: 'nearest',
          cornerRadius: 9,
          xPadding: 0,
          yPadding: 16,
          caretSize: 0,
          backgroundColor: '#ffffff',
          enabled: false,
          custom(tooltip) {
            if (!tooltip) return;
            // eslint-disable-next-line no-param-reassign
            tooltip.displayColors = false;

            let tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = '<table></table>';
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltip.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltip.yAlign) {
              tooltipEl.classList.add(tooltip.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltip.body) {
              const bodyLines = tooltip.body.map(getBody);

              let innerHtml = '<thead>';

              innerHtml += '</thead><tbody>';

              bodyLines.forEach((body, i) => {
                const colors = tooltip.labelColors[i];
                let style = `background: ${colors.backgroundColor}`;
                style += `; border-color: ${colors.borderColor}`;
                style += '; border-width: 1px';
                const span = `<span style="" ${style}'"></span>`;
                innerHtml += `<tr><td>
                  <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.713719 3.88921L0.71372 3.88921L0.714921 3.88866L8.01404 0.519832C8.12137 0.477571 8.25986 0.502623 8.37862 0.621383C8.49738 0.740143 8.52243 0.878629 8.48017 0.985956L5.11134 8.28508L5.11079 8.28628C4.94193 8.6547 4.43139 8.50553 4.43139 8.16079V5.06861V4.56861H3.93139H0.839206C0.494476 4.56861 0.345297 4.05807 0.713719 3.88921Z" fill="white" stroke="#121212"/>
                  </svg>
                  ${span} ${body} API Requests </td></tr>`;
              });
              innerHtml += '</tbody>';
              const tableRoot = tooltipEl.querySelector('table');
              tableRoot.innerHTML = innerHtml;
            }
            /* eslint no-underscore-dangle: 0 */
            const position = this._chart.canvas.getBoundingClientRect();

            tooltipEl.style.opacity = 1;
            tooltipEl.style.padding = '16px';
            tooltipEl.style.paddingRight = '25px';
            tooltipEl.style.backgroundColor = '#fff';
            tooltipEl.style.borderRadius = '9px';
            tooltipEl.style.fontSize = '14px';
            tooltipEl.style.boxShadow = '0px 1px 4px rgba(0, 0, 0, 0.15)';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = `${position.left + window.pageXOffset + tooltip.caretX}px`;
            tooltipEl.style.top = `${position.top + window.pageYOffset + tooltip.caretY}px`;
            tooltipEl.style.fontFamily = `${tooltip._bodyFontFamily}`;
            tooltipEl.style.pointerEvents = 'none';
          },
          callbacks: {
            label(tooltipItem) {
              // eslint-disable-next-line prefer-template
              const label = Math.round(tooltipItem.yLabel * 100) / 100;
              return label;
            },
            labelColor() {
              return {
                borderColor: 'rgb(0, 0, 0)',
                backgroundColor: 'rgb(0, 0, 0)',
              };
            },
            labelTextColor() {
              return '#121212';
            },
          },
        },
        legend: {
          display: false,
        },
      };
    },
  },
  borderWidth: {
    type: Number,
    default: 2,
  },
  elementRef: {
    type: String,
    default: 'myChart',
  },
  labels: {
    type: Array,
    default: null,
  },
};

export default {
};
